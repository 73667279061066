import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import erwin from '../assets/erwin.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 4),
  },
  container: {
    textAlign: 'center',
  },
  img: {
    border: '1px solid #d6d6d6',
    borderBottom: '1px solid #949494',
    borderRadius: '4px',
    maxWidth: '100%',
    boxShadow: '0 10px 6px -6px #777',
  },
  actions: {
    marginTop: theme.spacing(4),
  },
}));

function AboutText({ children }) {
  return (
    <Typography
      variant="h6"
      align="center"
      color="textSecondary"
      paragraph
    >
      {children}
    </Typography>
  )
}

function About() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} md={6}>
            <img
              className={classes.img}
              src={erwin}
              alt="Rain-Maker Gartenbewässerung"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AboutText>
              Hier finden Sie alles über professionelle Gartenbewässerungssysteme.
            </AboutText>
            <AboutText>
              Wir übernehmen alles - angefangen bei der Planung bis hin zur Installation.
            </AboutText>
            <AboutText>
              Gerne stehen wir Ihnen auch bei Fragen zur Seite!
            </AboutText>
            <Button
              component={Link}
              to="/kontakt"
              variant="outlined"
              color="primary"
            >
              Jetzt Kontakt aufnehmen!
            </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default About;
