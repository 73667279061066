import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

function Title({ children, centered, variant }) {
  return (
    <Typography
      variant={variant}
      component="h1"
      color="textPrimary"
      align={ centered ? 'center' : 'left' }
      gutterBottom
    >
      {children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

Title.defaultProps = {
  centered: false,
  variant: 'h2',
};

export default Title;
