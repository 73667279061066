import { useLayoutEffect } from 'react';

export const DEFAULT_TITLE = 'Rain-Maker Gartenbewässerung';

export default function useTitle(page, defaultTitle = DEFAULT_TITLE) {
  useLayoutEffect(() => {
    document.title = `${page} | ${defaultTitle}`;
    return () => {
      document.title = defaultTitle;
    }
  }, [page, defaultTitle]);
}
