import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Title from '../components/Title';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import useTitle from '../hooks/useTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function Imprint() {
  const classes = useStyles();
  useTitle('Impressum');

  return (
    <Container
      className={classes.root}
      component="section"
      maxWidth="lg"
    >
      <Title variant="h2" centered>
        Impressum
      </Title>
      <Paper className={classes.paper}>
        <Typography variant="h5" component="p" gutterBottom>
          Rain-Maker Gartenbewässerung
        </Typography>
        <Typography>Inhaber: Erwin Wever</Typography>
        <Typography>Syenvennweg 18</Typography>
        <Typography>48529 Nordhorn</Typography>
        <Typography>
          Telefon: <Link href="tel:+49 173 7106212" underline="none">+49 173 7106212</Link>
        </Typography>
        <Typography>
          E-Mail: <Link href="mailto:rain-maker@freenet.de" underline="none">rain-maker@freenet.de</Link>
        </Typography>
        <Typography>USt-IdNr.: DE 271984396</Typography>
      </Paper>
      <Paper className={classes.paper}>
        <Typography>
          <strong>Urheberrecht:</strong> Alle auf Rain-Maker erschienenen Beiträge und Abbildungen sind urheberrechtlich geschützt und dürfen weder reproduziert noch wiederverwendet oder für gewerbliche Zwecke verwendet werden, ein Zuwiderhandeln ist strafbar.
        </Typography>
      </Paper>
    </Container>
  );
}

export default Imprint;