import React from 'react';
import About from '../components/About';
import Showcase from '../components/Showcase';
import useTitle from '../hooks/useTitle';
import tropfbewaesserung from '../assets/tropfbewaesserung.jpg';
import anlage from '../assets/anlage2.jpg';
import sprenger from '../assets/sprenger.jpg';

const products = [
  {
    img: sprenger,
    name: 'Regengehäuse mit Rotatordüse',
    description: '',
  },
  {
    img: anlage,
    name: 'Magnetventilbox mit 4 Magnetventilen von Hunter®',
    description: '',
  },
  {
    img: tropfbewaesserung,
    name: 'Tropfrohr mit 2,2 l/h pro Tropfer',
    description: 'Eignet sich besonders für schmale Flächen.',
  },
];

function Home() {
  useTitle('Home');

  return (
    <React.Fragment>
      <About />
      <Showcase items={products} />
    </React.Fragment>
  );
}

export default Home;
