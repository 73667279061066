import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  item: {
    textAlign: 'center',
  },
}));

function ContactCard({ children, href, external }) {
  const classes = useStyles();

  const linkProps = {
    href,
    underline: 'none',
  };

  if (external === true) {
    linkProps.rel = 'noreferrer noopener external prerender';
    linkProps.target = '_blank';
  }

  return (
    <Link {...linkProps}>
      <Paper className={classes.paper}>
        <Typography
          className={classes.item}
          variant="h6"
          component="p"
        >
          {children}
        </Typography>
      </Paper>
    </Link>
  );
}

ContactCard.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  external: PropTypes.bool.isRequired,
};

ContactCard.defaultProps = {
  external: false,
};

export default ContactCard;
