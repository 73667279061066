import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../components/Title';
import useTitle from '../hooks/useTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

function PrivacyPolicy() {
  const classes = useStyles();
  useTitle('Datenschutz');

  return (
    <Container
      className={classes.root}
      component="section"
      maxWidth="lg"
    >
      <Title variant="h2" centered>
        Datenschutz
      </Title>
      <Paper className={classes.paper}>
        <Typography>
          Unsere Website enthält Links zu Seiten fremder Anbieter.
        </Typography>
        <Typography>
          Daraus kann nicht gefolgert werden, dass Rain-Maker diese direkt oder indirekt unterstützt.
        </Typography>
        <Typography>
          Rain-Maker ist für Inhalte fremder Anbieter nicht verantwortlich und macht sich deren Äußerungen nicht zu eigen.
        </Typography>
      </Paper>
    </Container>
  );
}

export default PrivacyPolicy;
