import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MenuIcon from '@material-ui/icons/Menu';
import ResponsiveDrawer from './ResponsiveDrawer';
import logo from '../assets/logo.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  logo: {
    width: '150px',
    flexGrow: 1,
    paddingTop: theme.spacing(0.5),
  },
}));

function Header() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawerOpen = () => {
    setDrawerOpen((open) => !open);
  };

  const resetDrawerOpen = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            className={classes.logo}
            variant="h5"
            color="inherit"
            noWrap
          >
            <Link
              component={RouterLink}
              to="/"
              underline="none"
              color="inherit"
            >
              <img
                className={classes.logo}
                src={logo}
                alt="RAIN-MAKER"
              />
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="facebook"
            rel="noreferrer noopener external prerender me"
            href="https://www.facebook.com/Rain-Maker-Gartenbew%C3%A4sserung-113811897003706"
            target="_blank"
            title="Facebook"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="instagram"
            rel="noreferrer noopener external prerender me"
            href="https://instagram.com/rain_maker_gartenbewasserung"
            target="_blank"
            title="Instagram"
          >
            <InstagramIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ResponsiveDrawer
        open={drawerOpen}
        toggleOpen={toggleDrawerOpen}
        resetOpen={resetDrawerOpen}
      />
    </>
  );
}

export default Header;
