import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from './ResponsiveDrawer';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    flexShrink: 0,
    [theme.breakpoints.up('lg')]: {
      marginLeft: drawerWidth,
    },
  },
}));

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
    >
      {`© ${new Date().getFullYear()} Rain-Maker`}
    </Typography>
  );
}

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        component="p"
      >
        Ihr Profi für Gartenbewässerungssysteme.
      </Typography>
      <Copyright />
    </footer>
  );
}

export default Footer;
