import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ShowcaseItem, { showcaseItemShape } from './ShowcaseItem';

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

function Showcase({ items }) {
  const classes = useStyles();

  return (
    <section>
      <Container className={classes.grid} maxWidth="md">
        <Grid container spacing={4}>
          {items.map((item) => (
            <ShowcaseItem key={item.name} item={item} />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

Showcase.propTypes = {
  items: PropTypes.arrayOf(showcaseItemShape).isRequired,
};

export default Showcase;
