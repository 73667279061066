import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import PolicyIcon from '@material-ui/icons/Policy';
import DescriptionIcon from '@material-ui/icons/Description';

export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
}));

function DrawerListItem({ href, icon, text }) {
  return (
    <ListItem
      component={Link}
      to={href}
      button
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText>
        {text}
      </ListItemText>
    </ListItem>
  );
}

function ResponsiveDrawer({ open, toggleOpen, resetOpen }) {
  const classes = useStyles();

  const drawer = (
    <div className={classes.drawerContainer}>
      <Divider />
      <List onClick={resetOpen}>
        <DrawerListItem
          href="/"
          icon={<HomeIcon />}
          text="Home"
        />
        <DrawerListItem
          href="/kontakt"
          icon={<InfoIcon />}
          text="Kontakt"
        />
        <DrawerListItem
          href="/datenschutz"
          icon={<PolicyIcon />}
          text="Datenschutz"
        />
        <DrawerListItem
          href="/impressum"
          icon={<DescriptionIcon />}
          text="Impressum"
        />
      </List>
    </div>
  );

  const container = window !== undefined ? window.document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="navigation">
      <Hidden lgUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={toggleOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <Toolbar />
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

ResponsiveDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  resetOpen: PropTypes.func.isRequired,
};

export default ResponsiveDrawer;
