import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

export const showcaseItemShape = PropTypes.shape({
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
});

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    paddingTop: '56.25%', // 16:9
  },
  content: {
    flexGrow: 1,
  },
}));

function ShowcaseItem({ item }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={item.img}
          title={item.name}
        />
        <CardContent className={classes.content}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
          >
            {item.name}
          </Typography>
          {/*<Typography>
            {item.description}
          </Typography>*/}
        </CardContent>
      </Card>
    </Grid>
  );
}

ShowcaseItem.propTypes = {
  item: showcaseItemShape.isRequired,
};

export default ShowcaseItem;
