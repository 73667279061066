import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import udNotFound from '../assets/undraw_not_found.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  img: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '40vw',
    },
  },
}));

function Page404() {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography
        variant="h4"
        component="p"
        align="center"
        gutterBottom
      >
        Seite wurde nicht gefunden
      </Typography>
      <img
        className={classes.img}
        src={udNotFound}
        alt="Die gewünschte Seite existiert nicht" />
    </Container>
  )
}

export default Page404;
