import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';
import ContactCard from '../components/ContactCard';
import Title from '../components/Title';
import useTitle from '../hooks/useTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  gridContainer: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
}));

function Contact() {
  const classes = useStyles();
  useTitle('Kontakt');

  return (
    <Container
      className={classes.root}
      component="section"
      maxWidth="lg"
    >
      <Title variant="h2" centered>
        Kontakt
      </Title>
      <Grid
        container
        className={classes.gridContainer}
        spacing={4}
      >
        <Grid item xs={12} md={6}>
          <ContactCard href="mailto:rain-maker@freenet.de">
            <EmailIcon className={classes.icon} />
            rain-maker@freenet.de
          </ContactCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactCard href="tel:+49 173 7106212">
            <PhoneIcon className={classes.icon} />
            +49 173 7106212
          </ContactCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactCard
            href="https://www.facebook.com/Rain-Maker-Gartenbew%C3%A4sserung-113811897003706"
            external
          >
            <FacebookIcon className={classes.icon} />
            Facebook
          </ContactCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactCard
            href="https://instagram.com/rain_maker_gartenbewasserung"
            external
          >
            <InstagramIcon className={classes.icon} />
            Instagram
          </ContactCard>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Contact;
