import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Imprint from '../pages/Imprint';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Page404 from '../pages/Page404';
import Contact from '../pages/Contact';

function Router() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/kontakt" component={Contact} />
      <Route path="/impressum" component={Imprint} />
      <Route path="/datenschutz" component={PrivacyPolicy} />
      <Route path="*" component={Page404} />
    </Switch>
  );
}

export default Router;
