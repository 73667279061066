import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.onError !== undefined) {
      this.props.onError(error, errorInfo);
    }
  }

  render() {
    const { FallbackComponent } = this.props;
    const { error } = this.state;

    if (error !== undefined) {
      return FallbackComponent !== undefined ? (
        <FallbackComponent error={error} />
      ) : (
        <Typography align="center" component="p" variant="h4">
          Error 404
        </Typography>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  onError: PropTypes.func,
  FallbackComponent: PropTypes.elementType,
};

export default ErrorBoundary;
