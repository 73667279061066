import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';
import Header from './components/Header';
import Router from './components/Router';
import { drawerWidth } from './components/ResponsiveDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    flex: 1,
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      marginLeft: drawerWidth,
    },
  },
  toolbar: theme.mixins.toolbar,
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ErrorBoundary onError={console.error}>
        <Header />
        <main className={classes.main}>
          <div className={classes.toolbar} />
          <Router />
        </main>
        <Footer />
      </ErrorBoundary>
    </div>
  );
}

export default App;
