import React from 'react';
import 'typeface-roboto';
import { render, hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { green, grey, red } from '@material-ui/core/colors';
import App from './App';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: green[800],
    },
    secondary: grey,
    error: red,
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const Tree = (
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </MuiThemeProvider>
);
const root = document.getElementById('root');

if (root.hasChildNodes()) {
  hydrate(Tree, root);
} else {
  render(Tree, root);
}
